import { ActionsGroup } from '@elotech/components';
import { PedidoActionButton } from 'itbi-common/components';
import React from 'react';

import { Roles } from '../../roles/Roles';

const onPrintBoletoDefault = urlBoleto => window.open(urlBoleto, '_blank');

const renderViewButton = (pedido, { onView }, props) => {
  return props.fab ? null : (
    <PedidoActionButton
      data-test-id="see-button"
      key="see-button"
      icon="eye"
      title="Ver pedido"
      onClick={() => onView(pedido)}
      {...props}
    />
  );
};

const renderNotifyButton = (pedido, { onNotify }, props, { hasRole }) =>
  hasRole(Roles.notificar_cidadao.name) ? (
    <PedidoActionButton
      data-test-id="notify-button"
      key="notify-button"
      icon="paper-plane"
      title="Notificar cidadão"
      onClick={() => onNotify(pedido)}
      {...props}
    />
  ) : null;

const renderGenerateDebitButton = (
  pedido,
  { onGenerateDebito },
  props,
  { hasRole }
) =>
  hasRole(Roles.itbi_gerar_debito.name) && !pedido.idDebito ? (
    <PedidoActionButton
      data-test-id="generate-debit-button"
      key="generate-debit-button"
      icon="dollar-sign"
      title="Gerar débito"
      onClick={() => onGenerateDebito(pedido)}
      {...props}
    />
  ) : null;

const renderEditButton = (pedido, { onEdit }, props, { hasRole }) => {
  if (
    hasRole(Roles.itbi_editar_pedido_terceiros.name) ||
    (pedido.responsavel &&
      props.currentUser &&
      pedido.responsavel.id === props.currentUser.id)
  ) {
    return (
      <PedidoActionButton
        data-test-id="edit-button"
        key="edit-button"
        icon="pencil-alt"
        title="Analisar pedido"
        onClick={() => onEdit(pedido)}
        {...props}
      />
    );
  }
  return null;
};

const renderEditRascunhoButton = (pedido, { onEditRascunho }, props) => {
  if (
    props.currentUser &&
    pedido.usuarioCpfCnpj === props.currentUser.username
  ) {
    return (
      <PedidoActionButton
        data-test-id="edit-button-rascunho"
        key="edit-button"
        icon="edit"
        title="Editar rascunho"
        onClick={() => onEditRascunho(pedido)}
        {...props}
      />
    );
  }
  return null;
};

const renderRemoveRascunhoButton = (pedido, { onRemoveRascunho }, props) => {
  if (
    props.currentUser &&
    pedido.usuarioCpfCnpj === props.currentUser.username
  ) {
    return (
      <PedidoActionButton
        data-test-id="remove-button-rascunho"
        key="remove-button"
        icon="trash-alt"
        title="Remover rascunho"
        onClick={() => onRemoveRascunho(pedido)}
        {...props}
      />
    );
  }
  return null;
};

const renderPrintBoletoButton = (requerimento, actions, props, { hasRole }) => {
  if (hasRole(Roles.itbi_imprimir_boleto.name) && requerimento.urlBoleto) {
    return (
      <PedidoActionButton
        data-test-id="pay-button"
        key="pay-button"
        icon="barcode"
        title="Imprimir Boleto"
        onClick={() => onPrintBoletoDefault(requerimento.urlBoleto)}
        {...props}
      />
    );
  }

  return null;
};

const renderGerarBoletoButton = (requerimento, { onGenerateBoleto }, props) => {
  if (!requerimento.boletoGerado && requerimento.idDebito) {
    return (
      <PedidoActionButton
        data-test-id="boleto-button"
        key="boleto-button"
        icon="barcode"
        title="Gerar Boleto"
        onClick={() => onGenerateBoleto(requerimento)}
        {...props}
      />
    );
  }

  return null;
};

const renderPrintButton = (requerimento, actions, props) =>
  props.fab ? (
    <PedidoActionButton
      data-test-id="imprimir-button"
      key="imprimir-button"
      icon="print"
      title="Imprimir"
      onClick={() => window.print()}
      {...props}
    />
  ) : null;

const renderPrintCertidaoQuitacaoButton = (
  requerimento,
  { onDownloadQuitacao },
  { certidaoSeTotalmenteIsento, ...props }
) => {
  const {
    tipoItbi: { isencao: isento },
    valorIsencao,
    valorTransacaoItbi
  } = requerimento;
  const hasIsencao =
    isento && valorIsencao > 0 && valorIsencao !== valorTransacaoItbi;
  const canRenderButton = !isento || (hasIsencao && certidaoSeTotalmenteIsento);
  const renderButton = (
    <PedidoActionButton
      key="print-quitacao-button"
      data-test-id={`print-quitacao-button-${requerimento.id}`}
      icon="file-download"
      title="Imprimir Certidão de Quitação"
      onClick={() => onDownloadQuitacao(requerimento)}
      {...props}
    />
  );

  return canRenderButton && renderButton;
};

const renderTransferirImovelButton = (
  pedido,
  { onTransferir },
  props,
  { hasRole }
) =>
  hasRole(Roles.itbi_transferir_manual.name) ? (
    <PedidoActionButton
      data-test-id="transferir-button"
      key="transferir-button"
      icon="exchange-alt"
      title="Transferir"
      onClick={() => onTransferir(pedido)}
      {...props}
    />
  ) : null;

const renderTransferirImovelSemPagamentoButton = (
  pedido,
  { onTransferirSemPagamento },
  props,
  { hasRole }
) =>
  hasRole(Roles.itbi_transferir_manual_sem_pagamento.name) ? (
    <PedidoActionButton
      key="transferir-sem-pagamento-button"
      icon="exchange-alt"
      title="Transferir sem Pagamento"
      onClick={() => onTransferirSemPagamento(pedido)}
      {...props}
    />
  ) : null;

const renderApagarGuiasPagamentoButton = (
  requerimento,
  { onApagarGuiasPagamento },
  props,
  { hasRole }
) =>
  props.fab &&
  requerimento.boletoGerado &&
  hasRole(Roles.apagar_guias_pagamento_declaracoes_laudos.name) ? (
    <PedidoActionButton
      key="apagar-guias-pagamento-button"
      data-test-id={`apagar-guias-pagamento-button-${requerimento.id}`}
      icon="trash-alt"
      title="Apagar Guias de Pagamento"
      onClick={() => onApagarGuiasPagamento(requerimento)}
      {...props}
    />
  ) : null;

const renderTransferirResponsavel = (
  pedido,
  { onTransferirResponsavel },
  { currentUser, ...props },
  { hasRole }
) => {
  if (
    hasRole(Roles.transferir_responsavel.name) ||
    (pedido.responsavel &&
      currentUser &&
      pedido.responsavel.id === currentUser.id)
  ) {
    return (
      <PedidoActionButton
        data-test-id="transferir-responsavel-button"
        key="transferir-responsavel-button"
        icon="user-edit"
        title="Transferir responsável"
        onClick={() => onTransferirResponsavel(pedido)}
        {...props}
      />
    );
  }
  return null;
};

const renderAnalizeContestButton = (
  pedido,
  { onAnalizeContestacao },
  { currentUser, ...props },
  { hasRole }
) => {
  if (
    hasRole(Roles.analisar_contestacao.name) ||
    (pedido.responsavel &&
      currentUser &&
      pedido.responsavel.id === currentUser.id)
  ) {
    return (
      <PedidoActionButton
        data-test-id="analize-contest-button"
        key="analize-contest-button"
        icon="check-circle"
        title="Analisar Contestação"
        onClick={() => onAnalizeContestacao(pedido)}
        {...props}
      />
    );
  }
  return null;
};

const renderReverseButton = (pedido, { onReverse }, props, { hasRole }) =>
  hasRole(Roles.itbi_estornar_pedido.name) ? (
    <PedidoActionButton
      data-test-id="estornar-button"
      key="estornar-button"
      icon="undo"
      title="Estornar"
      onClick={() => onReverse(pedido)}
      {...props}
    />
  ) : null;

const renderCancelarButton = (pedido, { onCancelar }, props, { hasRole }) =>
  hasRole(Roles.itbi_cancelar_pedido.name) ? (
    <PedidoActionButton
      data-test-id="cancelar-button"
      key="cancelar-button"
      icon="times"
      title="Cancelar"
      onClick={() => onCancelar(pedido)}
      {...props}
    />
  ) : null;

const renderFavoriteButton = (
  pedido,
  { onFavorite },
  { fab, ...props },
  { hasRole }
) => {
  if (fab) {
    return null;
  }

  if (!hasRole(Roles.acesso_prioritario.name)) {
    return null;
  }

  return (
    <PedidoActionButton
      data-test-id="favorite-button"
      key="favorite-button"
      icon={[pedido.prioritario ? 'fas' : 'far', 'star']}
      title="Prioritário"
      onClick={() => onFavorite(pedido)}
      {...props}
    />
  );
};

const renderViewFavoriteButton = (
  pedido,
  { onViewFavorite },
  { fab, ...props },
  { hasRole }
) => {
  if (fab) {
    return null;
  }

  if (!hasRole(Roles.acesso_prioritario.name) || !pedido.prioritario) {
    return null;
  }

  return (
    <PedidoActionButton
      data-test-id="view-favorite-button"
      key="view-favorite-button"
      icon={[pedido.prioritario ? 'fas' : 'far', 'question']}
      title="Motivo Prioritário"
      onClick={() => onViewFavorite(pedido)}
      {...props}
    />
  );
};

const renderPrintCertidaoIsencaoButton = (
  requerimento,
  { onPrintCertidaoIsencao },
  { certidaoSeTotalmenteIsento, ...props }
) => {
  const {
    tipoItbi: { isencao: isento },
    valorIsencao,
    valorTransacaoItbi
  } = requerimento;
  const isTotalmenteIsento =
    isento && valorIsencao > 0 && valorIsencao === valorTransacaoItbi;
  const isParcialmenteIsento =
    isento && valorIsencao > 0 && valorIsencao !== valorTransacaoItbi;
  const canRenderButton =
    isTotalmenteIsento || (isParcialmenteIsento && !certidaoSeTotalmenteIsento);
  const renderButton = (
    <PedidoActionButton
      key="print-isencao-button"
      data-test-id={`print-isencao-button-${requerimento}`}
      icon="file-alt"
      title="Imprimir Certidão de Isenção/Imunidade"
      onClick={() => onPrintCertidaoIsencao(requerimento)}
      {...props}
    />
  );

  return canRenderButton && renderButton;
};

const actionButtons = {
  RASCUNHO: [renderEditRascunhoButton, renderRemoveRascunhoButton],
  AGUARDANDO_ANALISE: [
    renderViewButton,
    renderFavoriteButton,
    renderViewFavoriteButton,
    renderPrintButton
  ],
  ANALISANDO: [
    renderViewButton,
    renderEditButton,
    renderTransferirResponsavel,
    renderPrintButton
  ],
  AGUARDANDO_GERACAO_BOLETO: [
    renderViewButton,
    renderGenerateDebitButton,
    renderGerarBoletoButton,
    renderTransferirResponsavel,
    renderPrintButton
  ],
  INDEFERIDO: [renderViewButton],
  AGUARDANDO_NOTIFICACAO: [
    renderReverseButton,
    renderPrintBoletoButton,
    renderViewButton,
    renderNotifyButton,
    renderTransferirResponsavel,
    renderPrintButton
  ],
  AGUARDANDO_PAGAMENTO: [
    renderCancelarButton,
    renderPrintBoletoButton,
    renderViewButton,
    renderPrintButton,
    renderApagarGuiasPagamentoButton,
    renderTransferirImovelSemPagamentoButton
  ],
  TRANSFERENCIA_MANUAL: [
    renderPrintBoletoButton,
    renderViewButton,
    renderTransferirImovelButton,
    renderPrintButton,
    renderPrintCertidaoQuitacaoButton,
    renderPrintCertidaoIsencaoButton
  ],
  TRANSFERIDO: [
    renderPrintBoletoButton,
    renderViewButton,
    renderPrintButton,
    renderPrintCertidaoQuitacaoButton,
    renderPrintCertidaoIsencaoButton
  ],
  EM_CONTESTACAO: [renderViewButton, renderAnalizeContestButton],
  AGUARDANDO_ACEITE: [renderViewButton]
};

export const renderActionButtons = (
  requerimento,
  actions,
  options = {},
  auth
) => {
  const situacao = requerimento.situacao || requerimento.situacaoITBI.name;
  return (
    <ActionsGroup>
      {(actionButtons[situacao] || [])
        .map(renderFunction =>
          renderFunction(requerimento, actions, options, auth)
        )
        .filter(item => !!item)}
    </ActionsGroup>
  );
};

export const renderFabButtons = (requerimento, actions, options = {}, auth) => {
  return (actionButtons[requerimento.situacaoItbi.name] || [])
    .map(renderFunction =>
      renderFunction(requerimento, actions, { ...options, fab: true }, auth)
    )
    .filter(item => !!item);
};
