import { DocumentacaoList } from 'itbi-common/components';
import PropTypes from 'prop-types';
import React from 'react';

const DocumentosImovelSection = ({ documentos, onDownload }) => {
  return (
    <div>
      <DocumentacaoList
        title="Documentos do Imóvel"
        documentos={documentos}
        onDownload={onDownload}
      />
    </div>
  );
};

DocumentosImovelSection.propTypes = {
  documentos: PropTypes.array.isRequired,
  onDownload: PropTypes.func.isRequired
};

export default DocumentosImovelSection;
