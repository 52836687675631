import {
  Alert,
  Col,
  Container,
  ErrorText,
  FAB,
  Loading,
  NotificationActions,
  Row,
  SectionTitle,
  withAuth
} from '@elotech/components';
import {
  AvaliacaoQuickView,
  ContestacaoSectionForm
} from 'itbi-common/components';
import {
  AvaliacaoService,
  DeclaracaoService,
  ParametroGeralService,
  ParametroService,
  ProprietarioService,
  SegmentoService,
  UploadService,
  withService
} from 'itbi-common/service';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import { DadosTransferencia } from './ResumoDeclaracao';
import SegmentoSection from './valores/SegmentoSection';
import ValoresTransferenciaSection from './valores/ValoresTransferenciaSection';

const moduloTributos = '05';

const valoresAvaliacao = {
  valorTransacaoAvaliacao: {
    aliquota: 'aliquotaTransacao',
    campo: 'valorTransacaoItbi'
  },
  valorFinanciadoAvaliacao: {
    aliquota: 'aliquotaFinanciado',
    campo: 'valorFinanciadoItbi'
  },
  valorIsencao: {
    aliquota: 'aliquotaTransacao',
    campo: 'valorIsencao'
  },
  percentualIsencao: {
    aliquota: 'aliquotaTransacao',
    campo: 'percentualIsencao'
  }
};

const errorMessages = {
  valorTransacaoAvaliacao: 'Valor é obrigatório!',
  valorFinanciadoAvaliacao: 'Valor é obrigatório!',
  valorIsencaoMenorIgualZero: 'Valor deve ser maior que 0!',
  valorIsencaoMaiorItbi:
    'Valor da Isenção não pode ser maior que o Valor ITBI!',
  percentualIsencaoMenorIgualZero: 'Percentual de Isenção de ser no mínimo 1%',
  percentualIsencaoMaiorCem: 'Percentual de Isenção de ser no máximo 100%',
  valorIsencao: 'Valor de isenção inválido'
};

class DeclaracaoContestacaoPage extends Component {
  static propTypes = {};

  state = {
    loading: true,
    declaracaoItbi: undefined,
    errors: {
      valorTransacaoAvaliacao: false,
      valorFinanciadoAvaliacao: false,
      valorIsencaoMenorIgualZero: false,
      valorIsencaoMaiorItbi: false,
      percentualIsencaoMenorIgualZero: false,
      percentualIsencaoMaiorCem: false,
      arquivoParecer: false
    },
    showAvaliacoes: false,
    ultimasAvaliacoes: undefined,
    arquivoParecerErrorMessage: undefined,
    valorRecursoProprio: false
  };

  componentDidMount() {
    const { params } = this.props.match;

    this.onGetDeclaracao(params.id);
    this.getParametroRecursoProprio();
  }

  getParametroRecursoProprio = () => {
    this.setState({ loading: true });
    this.props.parametroGeralService
      .findOne(moduloTributos, 'DESCVALORRECURSOPROPRIOITBI')
      .then(response => {
        if (response.data.valor === 'S') {
          this.setState({ valorRecursoProprio: true });
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  onGetDeclaracaoSuccess = response => {
    this.setState({
      declaracaoItbi: response.data,
      loading: false,
      valorRecursoProprio: true
    });
  };

  onGetDeclaracao = id => {
    this.setState({ loading: true }, () => {
      this.props.declaracaoService
        .findDeclaracaoItbiById(id)
        .then(this.onGetDeclaracaoSuccess)
        .catch(error => {
          Alert.error(
            {
              title: 'Erro ao carregar os dados da declaração.'
            },
            error
          );
        });
    });
  };

  informaValoresFinanciamento = declaracaoItbi =>
    declaracaoItbi.tipoItbi.financiado || declaracaoItbi.tipoItbi.anuencia;

  informaValoresFinanciamentoTotal = declaracaoItbi =>
    this.informaValoresFinanciamento(declaracaoItbi) &&
    declaracaoItbi.tipoItbi.permiteTotalmenteFinanciado;

  informaValorIsencao = declaracaoItbi => declaracaoItbi.tipoItbi.isencao;

  isValidValorTransacaoAvaliacao = valorTransacaoAvaliacao =>
    !valorTransacaoAvaliacao || valorTransacaoAvaliacao <= 0;

  isValidValorIsencao = (valorIsencao, percentualIsencao) =>
    (!valorIsencao || valorIsencao <= 0) && percentualIsencao === undefined;

  valorIsencaoMaiorItbi = (
    valorIsencao,
    valorTransacaoItbi,
    percentualIsencao
  ) => valorTransacaoItbi < valorIsencao && percentualIsencao === undefined;

  percentualIsencaoMaiorCem = percentualIsencao =>
    percentualIsencao > 100 && percentualIsencao !== undefined;

  percentualIsencaoMenorIgualZero = percentualIsencao =>
    (!percentualIsencao || percentualIsencao < 1) &&
    percentualIsencao !== undefined;

  errorValidators = {
    valorTransacaoAvaliacao: declaracaoItbi =>
      this.informaValoresFinanciamentoTotal(declaracaoItbi)
        ? false
        : this.isValidValorTransacaoAvaliacao(
            declaracaoItbi.valorTransacaoAvaliacao
          ),
    valorFinanciadoAvaliacao: declaracaoItbi =>
      this.informaValoresFinanciamento(declaracaoItbi) &&
      (!declaracaoItbi.valorFinanciadoAvaliacao ||
        declaracaoItbi.valorFinanciadoAvaliacao <= 0),
    valorIsencao: declaracaoItbi =>
      this.informaValorIsencao(declaracaoItbi)
        ? this.isValidValorIsencao(
            declaracaoItbi.valorIsencao,
            declaracaoItbi.percentualIsencao
          )
        : false,
    valorIsencaoMaiorItbi: declaracaoItbi =>
      this.informaValorIsencao(declaracaoItbi)
        ? this.valorIsencaoMaiorItbi(
            declaracaoItbi.valorIsencao,
            declaracaoItbi.valorTransacaoItbi,
            declaracaoItbi.percentualIsencao
          )
        : false,
    percentualIsencaoMenorIgualZero: declaracaoItbi =>
      this.informaValorIsencao(declaracaoItbi)
        ? this.percentualIsencaoMenorIgualZero(declaracaoItbi.percentualIsencao)
        : false,
    percentualIsencaoMaiorCem: declaracaoItbi =>
      this.informaValorIsencao(declaracaoItbi)
        ? this.percentualIsencaoMaiorCem(declaracaoItbi.percentualIsencao)
        : false
  };

  onValidateValoresTransferencia = declaracaoItbi => {
    const errors = {
      valorTransacaoAvaliacao: this.errorValidators.valorTransacaoAvaliacao(
        declaracaoItbi
      ),
      valorFinanciadoAvaliacao: this.errorValidators.valorFinanciadoAvaliacao(
        declaracaoItbi
      ),
      valorIsencao: this.errorValidators.valorIsencao(declaracaoItbi),
      valorIsencaoMaiorItbi: this.errorValidators.valorIsencaoMaiorItbi(
        declaracaoItbi
      ),
      percentualIsencaoMaiorCem: this.errorValidators.percentualIsencaoMaiorCem(
        declaracaoItbi
      ),
      percentualIsencaoMenorIgualZero: this.errorValidators.percentualIsencaoMenorIgualZero(
        declaracaoItbi
      )
    };

    const valid = !Object.values(errors).some(Boolean);

    if (!valid) {
      const error = Object.keys(errors)
        .filter(key => errors[key])
        .slice(0);
      const errorMessage = errorMessages[error];

      this.props.showNotification({
        level: 'error',
        message: errorMessage
      });
    }

    this.setState(state => {
      return {
        errors: {
          ...state.errors,
          ...errors
        }
      };
    });

    return valid;
  };

  parecerIndeferimentoValidator = value => {
    return new Promise(resolve => {
      if (value) {
        resolve();
        return;
      }

      resolve('Descreva o parecer do indeferimento!');
    });
  };

  indeferir = async declaracaoItbi => {
    const { showNotification, declaracaoService, history } = this.props;
    const { contestacao } = declaracaoItbi;

    const result = await Alert.question({
      title: 'Qual o parecer do indeferimento?',
      input: 'textarea',
      inputPlaceholder: 'Descreva o parecer do indeferimento',
      inputValidator: this.parecerIndeferimentoValidator,
      confirmButtonText: 'Indeferir e notificar requerente',
      cancelButtonText: 'Cancelar'
    });

    if (result.value) {
      this.setState({ loading: true });
      const motivoIndeferimento = {
        motivo: result.value,
        nomeArquivoParecer:
          contestacao.files &&
          contestacao.files.length &&
          (await this.saveFileOnS3(declaracaoItbi.id, contestacao.files[0]))
      };

      declaracaoService
        .indeferirByContestacao(declaracaoItbi.id, motivoIndeferimento)
        .then(() => {
          showNotification({
            level: 'success',
            message: 'Indeferimento da Contestação Finalizada'
          });
          history.push(`/declaracoes-itbi/${declaracaoItbi.id}/resumo`);
        })
        .catch(error => {
          Alert.error(
            { title: 'Falha ao indeferir a contestação do pedido!' },
            error
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  saveFileOnS3 = async (id, arquivo) => {
    this.setState({ loading: true });
    return await UploadService.getUrlUploadArquivoAvulsoDeclaracao(
      id,
      arquivo.name
    )
      .then(async urlResult => {
        console.log(
          'getUrlUploadArquivoAvulsoDeclaracao - response',
          urlResult
        );
        return await UploadService.uploadFileS3(urlResult.data.url, arquivo);
      })
      .then(() => {
        this.setState({ loading: false });
        return arquivo.name;
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao fazer upload do arquivo da contestação'
          },
          error
        );
        this.setState({ loading: false });
      });
  };

  onFinalizarContestacao = async () => {
    const {
      declaracaoItbi,
      declaracaoItbi: { contestacao, aliquotaTransacao }
    } = this.state;

    const { showNotification, declaracaoService, history } = this.props;

    const valid = this.onValidateValoresTransferencia(declaracaoItbi);

    if (!valid) {
      return;
    }

    const result = await Alert.question({
      title: 'Finalizou a contestação?',
      text: 'Escolha entre as opções para finalizar a contestação.',
      confirmButtonText: 'Deferir',
      cancelButtonText: 'Indeferir',
      allowOutsideClick: true
    });

    if (result.value) {
      this.setState({ loading: true });

      const finalizacaoContestacaoDTO = {
        id: declaracaoItbi.id,
        valorTransacaoAvaliacao:
          contestacao.valorProposto || declaracaoItbi?.valorTransacaoAvaliacao,
        valorTransacaoItbi:
          contestacao.valorProposto * (aliquotaTransacao / 100) ||
          declaracaoItbi.valorTransacaoItbi,
        valorFinanciadoAvaliacao: declaracaoItbi?.valorFinanciadoAvaliacao || 0,
        nomeArquivoParecer:
          contestacao.files &&
          contestacao.files.length &&
          (await this.saveFileOnS3(declaracaoItbi.id, contestacao.files[0]))
      };

      declaracaoService
        .finalizarContestacao(finalizacaoContestacaoDTO)
        .then(() => {
          showNotification({
            level: 'success',
            message: 'Deferimento da Contestação Finalizada'
          });
          history.push(`/declaracoes-itbi/${declaracaoItbi.id}/resumo`);
        })
        .catch(error => {
          return showNotification({
            level: 'warning',
            message: error.response.data.message
          });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else if (result.dismiss === swal.DismissReason.cancel) {
      this.indeferir(declaracaoItbi);
    }
  };

  onChangeValoresItbi = event => {
    const { name, value } = event.target;
    const campoValorItbi = valoresAvaliacao[name].campo;
    const aliquota = this.state.declaracaoItbi[
      `${valoresAvaliacao[name].aliquota}`
    ];
    const percentualIsencao = 'percentualIsencao';

    if (campoValorItbi === 'valorIsencao') {
      if (this.state.declaracaoItbi.percentualIsencao !== undefined) {
        this.setState(state => {
          return {
            declaracaoItbi: {
              ...state.declaracaoItbi,
              [name]: value,
              [campoValorItbi]: value,
              [percentualIsencao]: undefined
            },
            errors: {
              [name]: false
            }
          };
        });
      }
      this.setState(state => {
        return {
          declaracaoItbi: {
            ...state.declaracaoItbi,
            [name]: value,
            [campoValorItbi]: value
          },
          errors: {
            [name]: false
          }
        };
      });
    } else {
      this.setState(state => {
        return {
          declaracaoItbi: {
            ...state.declaracaoItbi,
            [name]: value,
            [campoValorItbi]: value * (aliquota / 100)
          },
          errors: {
            [name]: false
          }
        };
      });
    }
  };

  onChangePercentualIsencaoItbi = event => {
    const { name, value } = event.target;
    const campoValorItbi = valoresAvaliacao[name].campo;
    const valorIsencaoPercentual =
      this.state.declaracaoItbi.valorTransacaoItbi * (value / 100);
    const valorIsencao = 'valorIsencao';

    this.setState(state => {
      return {
        declaracaoItbi: {
          ...state.declaracaoItbi,
          [name]: value,
          [campoValorItbi]: value,
          [valorIsencao]: valorIsencaoPercentual
        },
        errors: {
          [name]: false
        }
      };
    });
    return null;
  };

  somaValorConstrucaoSegmento = () => {
    const {
      declaracaoItbi: { segmentos, valorTerritorialCalculado }
    } = this.state;
    const valorConstrucaoSegmentos = segmentos
      .map(segmento => segmento.valorConstrucao)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return valorConstrucaoSegmentos + valorTerritorialCalculado;
  };

  onShowAvaliacoes = () => {
    const { avaliacaoService, showNotification } = this.props;

    const {
      cadastroImobiliario,
      cadastroRural,
      tipoImovel
    } = this.state.declaracaoItbi;

    const cadastro =
      tipoImovel === 'URBANO' ? cadastroImobiliario : cadastroRural;
    this.setState({ loading: true });
    avaliacaoService
      .getUltimasAvaliacoes(cadastro.id, tipoImovel)
      .then(response => {
        if (response.data) {
          if (response.data.length === 0) {
            showNotification({
              level: 'info',
              message: 'Não foi encontrado nenhuma avaliação para este cadastro'
            });
          } else {
            this.setState({
              ultimasAvaliacoes: response.data,
              showAvaliacoes: true
            });
          }
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        showNotification({
          level: 'error',
          title: 'Erro',
          message: 'Ocorreu um erro ao buscar as avaliações'
        });
        this.setState({ loading: false });
      });
  };

  onChange = event => {
    const { name, value, checked, type } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    this.setState(state => {
      return {
        declaracaoItbi: { ...state.declaracaoItbi, [name]: newValue }
      };
    });
  };

  onCloseAvaliacoes = () => {
    this.setState({ showAvaliacoes: false });
  };

  onDownloadArquivoContestacao = urlArquivo => {
    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;

    return uploadService
      .getUrlDownloadArquivoAvulsoDeclaracao(declaracaoItbi.id, urlArquivo)
      .then(urlResult => uploadService.downloadFileS3(urlResult.data.url));
  };

  onUploadFile = event => {
    const { files } = event.target;

    const isValid = !(files && files[0] && files[0].size > 50000000);

    this.setState(prev => {
      return {
        ...prev,
        declaracaoItbi: {
          ...prev.declaracaoItbi,
          contestacao: {
            ...prev.declaracaoItbi.contestacao,
            files
          }
        },
        arquivoParecerErrorMessage: isValid
          ? undefined
          : 'Arquivo maior que 50Mb'
      };
    });
  };

  render() {
    const {
      loading,
      declaracaoItbi,
      errors,
      showAvaliacoes,
      ultimasAvaliacoes,
      valorRecursoProprio
    } = this.state;

    if (loading) {
      return <Loading loading={true} />;
    }

    const { auth } = this.props;

    const valoresItbi = {
      valorTransacao: declaracaoItbi.valorTransacao,
      valorTransacaoAvaliacao: declaracaoItbi.valorTransacaoAvaliacao,
      aliquotaTransacao: declaracaoItbi.aliquotaTransacao,
      valorTransacaoItbi: declaracaoItbi.valorTransacaoItbi,
      valorIsencao: declaracaoItbi.valorIsencao
    };

    const valoresFinanciamento = {
      valorFinanciado: declaracaoItbi.valorFinanciado,
      valorFinanciadoAvaliacao: declaracaoItbi.valorFinanciadoAvaliacao,
      aliquotaFinanciado: declaracaoItbi.aliquotaFinanciado,
      valorFinanciadoItbi: declaracaoItbi.valorFinanciadoItbi
    };

    return (
      <Container title="Análise de contestação da declaração ITBI" icon="paste">
        {showAvaliacoes && (
          <AvaliacaoQuickView
            onClose={this.onCloseAvaliacoes}
            ultimasAvaliacoes={ultimasAvaliacoes}
          />
        )}
        <DadosTransferencia
          dadosTransferencia={declaracaoItbi}
          hasContestacao={true}
          valorRecursoProprio={valorRecursoProprio}
        />
        <ContestacaoSectionForm
          contestacao={declaracaoItbi.contestacao}
          onDownload={this.onDownloadArquivoContestacao}
          isVisibleAllInformations={false}
        />
        <Row>
          <Col sm={6} className="form-group">
            <SectionTitle> Arquivo de parecer </SectionTitle>
            <div className="file-uploader">
              <input
                id="upload-file-input"
                type="file"
                className={`file-uploader-input${
                  this.state.arquivoParecerErrorMessage ? ' error' : ''
                }`}
                title="Clique ou arraste para anexar"
                onChange={value => this.onUploadFile(value)}
              />

              <label
                htmlFor="upload-file-input"
                className="input"
                data-title={
                  declaracaoItbi.contestacao.files &&
                  declaracaoItbi.contestacao.files.length > 0
                    ? declaracaoItbi.contestacao.files[0].name
                    : 'Clique ou arraste para anexar'
                }
              />

              <label
                htmlFor="upload-file-input"
                className="file-uploader-icon"
              />
            </div>
            {this.state.arquivoParecerErrorMessage && (
              <ErrorText>{this.state.arquivoParecerErrorMessage}</ErrorText>
            )}
          </Col>
        </Row>
        <ValoresTransferenciaSection
          tipoItbi={declaracaoItbi.tipoItbi}
          valoresItbi={valoresItbi}
          valoresFinanciamento={valoresFinanciamento}
          valorCalculado={this.somaValorConstrucaoSegmento()}
          onChangeValoresItbi={this.onChangeValoresItbi}
          onChangePercentualIsencaoItbi={this.onChangePercentualIsencaoItbi}
          errors={errors}
          errorMessages={errorMessages}
          onShowAvaliacoes={this.onShowAvaliacoes}
          hasRole={auth.hasRole}
          onChangeObservacao={this.onChange}
        />
        {declaracaoItbi.tipoImovel === 'URBANO' && (
          <SegmentoSection
            segmentos={declaracaoItbi.segmentos}
            onChange={this.onChange}
            hasRole={auth.hasRole}
            hasContestacao={true}
          />
        )}

        <div className="btn-save">
          <FAB
            data-test-id="button-finalizar-contestacao"
            icon="check"
            iconColor="white"
            title="Finalizar Contestação"
            onClick={this.onFinalizarContestacao}
          />
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  declaracaoService: DeclaracaoService,
  uploadService: UploadService,
  proprietarioService: ProprietarioService,
  segmentoService: SegmentoService,
  avaliacaoService: AvaliacaoService,
  parametroGeralService: ParametroGeralService,
  parametroService: ParametroService
})(DeclaracaoContestacaoPage);

const ComponentWithAuth = withAuth(ComponentWithService);

const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(ComponentWithAuth);

export { ConnectedComponent as default, DeclaracaoContestacaoPage };
